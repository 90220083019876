import Layout from '@/layout';

export default [{
  path: '/user',
  component: Layout,
  redirect: '/user/index',
  name: 'user',
  meta: { title: '账户管理', icon: 'user', permissions: ['account_manage'], position: 1 },
  children: [{
    path: 'index',
    component: () => import(/* webpackChunkName: 'user' */ '@/views/user/index'),
    name: 'UserIndex',
    meta: { title: '用户', permissions: ['user/index'] }
  }, {
    path: 'role',
    component: () => import(/* webpackChunkName: 'role' */ '@/views/user/role'),
    name: 'UserRole',
    meta: { title: '角色', permissions: ['user/role'] }
  }, {
    path: 'log',
    component: () => import(/* webpackChunkName: 'role' */ '@/views/user/optlog'),
    name: 'OptLog',
    meta: { title: '操作日志', permissions: ['user/log'] }
  }]
}];
