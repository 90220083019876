import request from '@/utils/request';

export default {
  /*
   * 用户登录
   * */
  doLogin: data => request.post('/user/login', data),

  /*
   * 用户退出
   * */
  goLogout: () => request.post('/user/logout'),

  /**
   * @description 操作日志
   * @returns
   */
  optLogList: data => request.get('/report/operate/record', { params: data }),

  /**
   * @description 获取用户权限
   * @returns
   */
  getUserPremissions: () => request.get('/user/mine')
};
