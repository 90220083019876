import Layout from '@/layout';

export default [{
  path: '/notice',
  component: Layout,
  redirect: '/notice/family',
  name: 'notice',
  meta: { title: '公告配置', icon: 'form', permissions: ['notice/family'] },
  children: [
    {
      path: 'family',
      component: () => import('@/views/notice/family'),
      name: 'familyNotice',
      meta: { title: '家族长公告', permissions: ['notice/family'] }
    }
  ]
}];
