import Layout from '@/layout';

export default [{
  path: '/starcret',
  component: Layout,
  redirect: '/starcret/audit',
  name: 'starcret',
  meta: { title: '嘿星审核', icon: 'star', noCache: false, permissions: ['starcret/audit'], position: 8 },
  children: [
    {
      path: 'order',
      component: () => import('@/views/starcret/audit'),
      name: 'starOrder',
      meta: { title: '嘿星审核', noCache: false, permissions: ['starcret/audit'], isParent: true }
    }
  ]
}];
