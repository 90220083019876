import { generateAUDRoute } from '../../framework/utils/route';
import Layout from '@/layout';

export default [{
  path: '/taobao',
  component: Layout,
  redirect: '/taobao/index',
  name: 'taobao',
  meta: { title: '淘宝小店', icon: 'taobao', permissions: ['taobao/index'], position: 4 },
  children: [{
    path: 'index',
    component: () => import(/* webpackChunkName: 'activity' */ '@/views/taobao/index'),
    name: 'TaobaoStore',
    meta: { title: '淘宝小店', permissions: ['taobao/index'] }
  },
  ...generateAUDRoute('taobao', '淘宝小店', 'taobao')]
}];
