import Layout from '@/layout';

export default [{
  path: '/finance',
  component: Layout,
  redirect: '/finance/remit',
  name: 'finance',
  meta: { title: '财务管理', icon: 'star', permissions: ['settle_manage'], position: 9 },
  children: [
    {
      path: '/finance/remit',
      // component: () => import('@/views/finance/components/remit-audit'),
      component: () => import(/* webpackChunkName: 'activity' */ '@/views/finance/remit-audit'),
      name: 'FinanceRemit',
      meta: { title: '打款审核', permissions: ['finance/remit'] },
      hidden: true
    },
    {
      path: '/finance/cloud',
      component: () => import(/* webpackChunkName: 'activity' */'@/views/finance/cloud-account'),
      name: 'FinanceCloud',
      meta: { title: '云账户管理', permissions: ['finance/cloud'] },
      hidden: true
    },
    {
      path: '/finance/record',
      component: () => import(/* webpackChunkName: 'activity' */'@/views/finance/operating-record'),
      name: 'FinanceRecord',
      meta: { title: '操作记录', permissions: ['finance/record'] },
      hidden: true
    }
  ]
}];
