import Layout from '@/layout';

export default [{
  path: '/diamond',
  component: Layout,
  redirect: '/diamond/index',
  name: 'diamond',
  meta: { title: '钻石流水查询', icon: 'component', permissions: ['diamond/index'] },
  children: [{
    path: 'index',
    component: () => import(/* webpackChunkName: 'activity' */ '@/views/diamond/index'),
    name: 'DiamondRecord',
    meta: { title: '钻石流水查询', permissions: ['diamond/index'] }
  }
  ]
}];
