import Layout from '@/layout';
import { generateAUDRoute } from '../../framework/utils/route';

export default [{
  path: '/halawhitelist',
  component: Layout,
  redirect: '/halawhitelist/index',
  name: 'halawhitelist',
  meta: { title: 'Hala白名单', icon: 'component', permissions: ['halawhitelist/index'] },
  children: [{
    path: 'index',
    component: () => import(/* webpackChunkName: 'activity' */ '@/views/halawhitelist/index'),
    name: 'halawhitelist',
    meta: { title: 'Hala白名单', permissions: ['halawhitelist/index'] }
  },
  ...generateAUDRoute('halawhitelist', 'Hala白名单', 'halawhitelist')
  ]
}];
